import { createRouter, createWebHistory } from 'vue-router'

import auth from '@/auth'
import store from '@/store'

const routes = [
  {
    path: '/fag',
    name: 'Fag',
    component: () => import('../views/Home.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/fag/:parent/:chapter',
    name: 'FagChapters',
    component: () => import('../views/ArchiveFag.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/fag/:parent/:chapter/:content',
    name: 'FagContent',
    component: () => import('../views/SingleContent.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/',
    name: 'Login',
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/verktoy',
    name: 'Tools',
    component: () => import('../views/Tools.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/fagbegreper',
    alias: '/fagomgrep',
    name: 'ConceptsSearch',
    component: () =>
      import(
        /* webpackChunkName: "conceptsSearch" */ '@/views/ConceptsSearch.vue'
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/theme',
    name: 'Theme',
    component: () => import('../views/Theme.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/theme/:theme',
    name: 'SingleTheme',
    component: () => import('../views/SingleTheme.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/theme/:theme/exercises/:exercise',
    name: 'SingleThemeExercise',
    component: () => import('../views/SingleThemeExercise.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/case',
    name: 'Case',
    component: () => import('../views/Case.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('../views/Settings.vue'),
    meta: {
      requiresAuth: true,
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (
      auth.isAuthenticated() &&
      auth.token &&
      typeof auth.token !== 'undefined'
    ) {
      next()
    } else {
      try {
        let resCA
        const user = await auth.checkToken()

        store.dispatch('user/setToken')
        store.dispatch('user/setUser', user)
        if (store.state.user.role === 'teacher') {
          resCA = await auth.checkAccess([
            process.env.VUE_APP_TEACHER_ACCESS_ID,
          ])
          if (
            resCA.products.some(
              (product) => product.id === process.env.VUE_APP_TEACHER_ACCESS_ID,
            )
          ) {
            store.dispatch('user/setTeacherAccess', true)
          }
        }
        next()
      } catch {
        next({ path: '/' })
      }
    }
  } else {
    next()
  }
})

export default router
