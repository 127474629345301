const nb = {
  'SIDEBAR_WELCOME': 'Hei',
  'SIDEBAR_HAS_TEACHER_ACCESS': 'Du har lærertilgang',
  'SIDEBAR_SETTINGS': 'Innstillinger',
  'SIDEBAR_FAG': 'Fag',
  'SIDEBAR_TOOLS': 'Verktøy',
  'SIDEBAR_THEME': 'Tverrfaglige temaer',
  'SIDEBAR_CASE': 'Tverrfaglige oppgaver/caser',
  'SIDEBAR_TOGGLE': 'Toggle sidebar',
  'SIDEBAR_CONCEPTS': 'Begrepsliste',
  'TOPBAR_SEARCH': 'Søk',
  'LOGOUT': 'Logg ut',
  'ARCHIVE_FAG_PRE_HEADING': 'Kapittel',
  'ARCHIVE_FAG_BTN_TEXT': 'Velg',
  'COMING': 'Kommer',
  'TABLE_OF_CONTENT': 'Innholdsfortegnelse',
  'TOOLS_TITLE': 'Verktøy',
  'TOOLS_SEE_MORE': 'Les mer',
  'TOOLS_DOWNLOAD': 'Last ned',
  'TOOLS_SHOW': 'Vise',
  'LOAD_MORE': 'Last mer',
  'BREADCRUMBS_BACK': 'Tilbake',
  'CONTENT_PRE_HEADING': 'Kapittel',
  'LOGIN_HEADING': 'Velkommen til Økonomiportalen!',
  'LOGIN_TEXT': `
    <p>
      Felles nettressurs for bøkene: 
      Brand 1.0 og 2.0, Økonomi 1 og 2, Oppstart og Vekst.
    </p>
    <p>
      Læreverkene omfatter programfagene:
      <ul>
        <li>Markedsføring og ledelse</li>
        <li>Næringslivsøkonomi</li>
        <li>Entreprenørskap og bedriftsutvikling</li>
      </ul>
    </p>
  `,
  'LOGIN_BUTTON': 'Logg inn',
  "CONCEPTS_CARD_BUTTON_TEXT": "Les mer >",
  "CONCEPTS_SEARCH_NO_RESULTS": "Ingen resultater",
  "CONCEPTS_SEARCH_PLACEHOLDER": "Søk i begreper",
  "CONCEPTS_LANG_LABEL": "Oversettelse",
  "CONCEPTS_LANG_PLACEHOLDER": "Ingen",
  "CONCEPTS_SEARCH_LOADING": "Laster",
}

export default nb
