<template>
  <div class="c-sidebar" :class="{ 'c-sidebar--expanded': isExpanded }">
    <div class="c-sidebar__wrapper">
      <div class="c-sidebar__logo-wrapper">
        <TheLogo class="c-sidebar__logo" @click.prevent="closeMenu('/')" />
      </div>

      <div class="c-sidebar__user-welcome">
        <TheHeading level="h3" class="c-sidebar__user-welcome__header"
          >{{ $t('SIDEBAR_WELCOME') }} {{ userName }}! 👋</TheHeading
        >
        <span v-if="userHasTeacherRole" class="c-sidebar__user-welcome__role">
          {{ $t('SIDEBAR_HAS_TEACHER_ACCESS') }}</span
        >
      </div>

      <div class="o-separator c-sidebar__only-mobile" />

      <SidebarMenu class="c-sidebar__menu" @close-menu="closeMenu" />

      <div class="o-separator c-sidebar__only-mobile" />

      <TheButton to="/settings" class="c-sidebar__settings" icon="IconSettings">
        {{ $t('SIDEBAR_SETTINGS') }}
      </TheButton>

      <LanguageSelect />

      <TheButton class="c-sidebar__logout" icon="IconLogout" @click="logout">
        {{ $t('LOGOUT') }}
      </TheButton>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import TheButton from '@/components/TheButton'
import TheLogo from '@/components/TheLogo'
import TheHeading from '@/components/TheHeading'
import SidebarMenu from '@/components/SidebarMenu'
import LanguageSelect from '@/components/LanguageSelect'

export default {
  name: 'Sidebar',

  components: {
    TheButton,
    TheLogo,
    TheHeading,
    SidebarMenu,
    LanguageSelect,
  },

  setup() {
    const store = useStore()
    const router = useRouter()
    const isExpanded = ref(true)
    const userName = computed(() => store.getters['user/userName'])
    const userHasTeacherRole = computed(
      () => store.state.user.role === 'teacher',
    )
    const closeMenu = (to) => {
      router.push(to)
      document.querySelector('#app').classList.remove('show-menu')
    }
    const logout = () => {
      store.dispatch('user/logout')
    }

    return {
      isExpanded,
      userName,
      userHasTeacherRole,
      closeMenu,
      logout,
    }
  },
}
</script>

<style lang="scss">
.c-sidebar {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  background: $color-white;
  z-index: 1;
  overflow: auto;
  width: 16.3125rem;

  @include bp(large) {
    display: block;
    width: 17rem;
  }
  @include bp-down(large) {
    .show-menu & {
      display: block;
    }
  }
  @include bp-down(small) {
    .show-menu & {
      display: flex;
    }
  }
}

.c-sidebar__wrapper {
  padding: 3.125rem 1rem;

  @include bp-down(small) {
    width: 100%;
  }
}

.c-sidebar__logo-wrapper {
  text-align: center;

  @include bp-down(large) {
    margin-bottom: 2.1875rem;
  }
}

.c-sidebar__user-welcome {
  margin-top: 11.5rem;

  @include bp-down(large) {
    display: none;
  }

  &__header {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.46625rem;
    color: $color-blue-dark;
    margin-bottom: 0.5rem;
  }
}

.c-sidebar__menu {
  margin-top: 3.125rem;
  margin-bottom: 3.75rem;
}

.c-sidebar__settings {
  // EP-138: hidden until settings functionality is ready
  display: none;
  margin-top: 1.5rem;

  @include bp-down(large) {
    display: none;
  }
}

.c-sidebar__only-mobile {
  @include bp(large) {
    display: none;
  }
}

.c-sidebar__logout {
  margin-top: 1.5rem;

  @include bp(large) {
    display: none;
  }
}
</style>
