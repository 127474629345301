<template>
  <div class="c-topbar">
    <TheButton
      class="c-topbar__hamburger"
      icon="IconHamburger"
      @click="toggleMenu"
    >
    </TheButton>
    <TheInput
      class="c-topbar__search"
      :placeholder="$t('TOPBAR_SEARCH')"
      icon="IconSearch"
    />
    <LanguageSelect
      :current-language="locale"
      :language-list="langs"
      @language-change="langChanged"
    />
    <TheButton class="c-topbar__logout" icon="IconLogout" @click="logout">
      {{ $t('LOGOUT') }}
    </TheButton>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { langs } from '@/i18n/langs'
import { settings, createOrUpdateSetting } from '@/services/userSettings'

import TheButton from '@/components/TheButton'
import TheInput from '@/components/TheInput'
import LanguageSelect from '@/components/LanguageSelect'

export default {
  name: 'Topbar',

  components: {
    TheButton,
    TheInput,
    LanguageSelect,
  },

  setup() {
    const store = useStore()
    const { locale } = useI18n()
    const toggleMenu = () => {
      document.querySelector('#app').classList.toggle('show-menu')
    }
    const logout = () => {
      store.dispatch('user/logout')
    }
    const langChanged = (lang) => {
      locale.value = lang
      createOrUpdateSetting(settings.lang, lang)
    }

    return {
      locale,
      langs,
      langChanged,
      logout,
      toggleMenu,
    }
  },
}
</script>

<style lang="scss">
.c-topbar {
  display: flex;
  align-items: center;
  margin-bottom: 3rem;

  &__logout {
    margin-left: 1rem;
    color: $color-blue-grey;
    font-weight: 600;
    font-size: 1rem;

    svg {
      height: 1.15rem;
      margin-right: 0.6rem;

      path {
        stroke: $color-blue-grey;
      }
    }

    @include bp-down(large) {
      display: none;
    }
  }

  &__hamburger {
    margin-right: 0.75rem;

    @include bp(large) {
      display: none;
    }
  }
  &__search {
    display: none;
    @include bp-down(small) {
      //display: flex;
      //EP8-136 Temporary hide - search pending funcionality.
      .show-menu & {
        display: none;
      }
    }
  }
}
</style>
