import { fetchSH } from '@/api'
import { buildQuery } from '@/utils'
import useTranslations from '@/composables/useTranslations'

const endpoint = 'contents'

/* Content Objects CRUD */
const getObjectsAll = (namespace, query) => fetchSH(`${endpoint}/${namespace}/objects${buildQuery(query)}`)

const getObject = async (namespace, id) => {
  const { gqlContentQuery } = useTranslations()

  return await gqlContentQuery(id)
}

export {
  getObjectsAll,
  getObject,
}
