import showdown from 'showdown'

const addCloudinaryUrlParams = (url, param) => {
  let newURL
  const splitURL = url.split('upload/')

  newURL = `${splitURL[0]}upload/${param}/${splitURL[1]}`

  return newURL
}

const markdownToHtml = md => {
  const converter = new showdown.Converter()
  converter.setOption('tables', true)
  converter.setOption('backslashEscapesHTMLTags', true)

  return converter.makeHtml(md)
}

const buildQuery = query => {
  let queryString = ''

  Object.keys(query).forEach((k, i) => {
    if (i === 0) {
      queryString = `?${k}=${query[k]}`
    } else {
      queryString = queryString + `&${k}=${query[k]}`
    }
  })

  return queryString
}

const sortItemsByOrder = items => items.sort((a, b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0))

export {
  addCloudinaryUrlParams,
  markdownToHtml,
  buildQuery,
  sortItemsByOrder
}
