import MoAuth from '@mo-platform/auth'

const addDev = () => (process.env.NODE_ENV === 'development' ? '.dev' : '')

const auth = new MoAuth({
  loginUrl: `https://auth-proxy${addDev()}.edtech.fagbokforlaget.no/auth/eportaloidc/edtech/login`,
  logoutUrl: `https://auth-proxy${addDev()}.edtech.fagbokforlaget.no/auth/eportaloidc/edtech/logout?redirect_url=${encodeURIComponent(
    window.location.origin,
  )}`,
  userFetchUrl: `https://auth-proxy${addDev()}.edtech.fagbokforlaget.no/auth/eportaloidc/edtech/userinfo`,
  accessCheckUrl: `https://auth-proxy${addDev()}.edtech.fagbokforlaget.no/auth/eportaloidc/edtech/roles`,
})

export default auth
