<template>
  <component :is="currentComponent" />
</template>

<script>
import { defineAsyncComponent } from '@vue/runtime-core'

export default {
  name: 'Icon',

  props: {
    icon: String
  },

  setup(props) {
    const currentComponent = defineAsyncComponent(() => import(`@/assets/svg/${props.icon}`))

    return {
      currentComponent
    }
  }
}
</script>