<template>
  <label class="c-input__label">
    <Icon v-if="icon" :icon="icon" />
    <input
      class="c-input"
      @input="$emit('input', $event)"
      :placeholder="placeholder"
    />
  </label>
</template>

<script>
import Icon from '@/components/Icon'

export default {
  name: 'TheInput',

  components: {
    Icon,
  },

  props: {
    icon: String,
    placeholder: String,
  },
}
</script>

<style lang="scss">
.c-input {
  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  color: $color-blue;
  font-size: 1rem;
  font-weight: 600;
  margin-left: 0.5rem;
  display: inline-block;
  width: 100%;

  &::placeholder {
    color: $color-blue;
  }
  &__label {
    display: flex;
    align-items: center;
  }
}
</style>
