const SET_STRUCTURES = (state, payload) => {
  payload.data.forEach(p => {
    const i = state.structures.findIndex(item => item.id === p.id)

    if (i !== -1) {
      state.structures.splice(i, 1)
    }

    state.structures.push(p)
  })
}

const SET_OBJECTS = (state, payload) => {
  payload.data.forEach(p => {
    const i = state.objects.findIndex(item => item.id === p.id)

    if (i !== -1) {
      state.objects.splice(i, 1)
    }

    state.objects.push(p)
  })
}

export default {
  SET_STRUCTURES,
  SET_OBJECTS
}
