<template>
  <div class="c-language-select u-px">
    <div v-if="label" class="c-language-select__label">{{ label }}:</div>
    <Multiselect
      v-model="selected"
      :options="languageList"
      :can-clear="canClear"
      :can-deselect="canDeselect"
      :placeholder="placeholder"
      @change="onSelectChange"
    />
  </div>
</template>

<script>
import { defineComponent, onBeforeMount, ref, watch } from 'vue'
import Multiselect from '@vueform/multiselect'
import '@vueform/multiselect/themes/default.css'

export default defineComponent({
  name: 'LanguageSelect',

  props: {
    languageList: String,
    currentLanguage: String,
    canClear: Boolean,
    canDeselect: Boolean,
    placeholder: String,
    label: String,
  },

  components: {
    Multiselect,
  },

  setup(props, { emit }) {
    const selected = ref('')
    onBeforeMount(() => {
      selected.value = props.currentLanguage
    })
    const onSelectChange = (event) => {
      emit('languageChange', event)
    }

    watch(
      () => props.currentLanguage,
      (parentLang) => {
        if (selected.value !== parentLang) {
          selected.value = parentLang
        }
      },
    )

    return {
      selected,
      onSelectChange,
    }
  },
})
</script>

<style lang="scss">
.c-language-select {
  display: flex;
  align-items: center;
  padding-left: 1rem;
  justify-content: flex-end;

  @include bp-down(medium) {
    width: fit-content;
    justify-content: start;
  }

  .c-topbar & {
    margin-left: auto;
    margin-right: 0;
    padding-left: 0;
    width: 10rem;
    border: $language-select__border solid 0.125rem;

    @include bp-down(large) {
      display: none;
    }
  }

  .c-sidebar & {
    display: none;
    padding-left: 0;
    width: 100%;
    border: $language-select__border solid 0.125rem;

    @include bp-down(large) {
      display: block;
    }
  }

  .multiselect {
    border: 0;
    box-shadow: none;
    background-color: $language-select__bg;
    flex: none;

    .c-topbar & {
      width: 10rem;
    }

    .c-sidebar & {
      width: 100%;
    }
  }

  .multiselect-single-label {
    font-weight: 700;
  }

  .multiselect-caret {
    margin-right: 0.25rem;
    background-color: $language-select__chevron;

    .c-topbar &,
    .c-sidebar & {
      margin-right: 0.5rem;
    }
  }

  .multiselect-placeholder {
    font-weight: 700;
    color: $language-select__placeholder;
  }

  .multiselect-dropdown {
    left: 0.25rem;
    border-radius: 0;
    border: 0;

    .c-topbar &,
    .c-sidebar & {
      left: -0.125rem;
      width: 100%;
      border: $language-select__border solid 0.125rem;
      border-top: 0;
      overflow-y: initial;
    }
  }

  .multiselect-no-options {
    display: none;
  }

  .multiselect-option {
    color: $language-select__text;
    background-color: $language-select__bg;

    &.is-selected {
      color: $language-select__text;
      font-weight: bold;
    }
    &.is-pointed {
      background-color: $language-select--hover__bg;
    }
  }

  .c-nav-mobile & {
    padding-left: 0;
    margin: auto;
    height: 4rem;

    .multiselect-option {
      background-color: $language-select--mobile__bg;
    }
  }
}

.c-language-select__label {
  margin-right: 1rem;
}
</style>
