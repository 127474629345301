import auth from '@/auth'

const SET_ACCESS_TOKEN = (state) => {
  state.accessToken = auth.token
}

const SET_USER = (state, user) => {
  state.user = user
}

const SET_USER_ROLE = (state, role) => {
  state.role = role
}

const SET_TEACHER_ACCESS = (state, hasAccess) => {
  state.hasTeacherAccess = hasAccess
}

const LOGOUT_USER = (state) => {
  state.accessToken = null
  state.user = null
}

export default {
  SET_ACCESS_TOKEN,
  SET_USER,
  SET_USER_ROLE,
  SET_TEACHER_ACCESS,
  LOGOUT_USER,
}
