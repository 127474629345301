<template>
  <ModalWrapper
    v-if="modalContent && contentItems.length"
    :title="modalContent.title"
  >
    <template v-slot:content>
      <div class="c-modal-concept__wrapper">
        <TheHeading class="c-modal__top-heading">
          {{ modalContent.title }}
        </TheHeading>
        <TheButton
          class="c-modal-concept__top-close"
          icon="IconClose"
          @click="closeModal"
        />
        <div class="c-modal-concept__content-wrapper">
          <ContentRenderer
            v-if="contentItems.length"
            :content-items="contentItems"
            borderless
          />
          <div
            :class="[
              'c-modal-concept__translated-content',
              `c-modal-concept__translated-content--${direction}`,
            ]"
            :dir="direction"
          >
            <ContentRenderer
              v-if="conceptLocale && translatedItems.length"
              :content-items="translatedItems"
              borderless
            />
          </div>
        </div>
      </div>
    </template>
  </ModalWrapper>
</template>

<script>
import { defineComponent, computed, onMounted } from 'vue'
import { useContents, useStructures, useLocalizations, useTenant } from '@/core'
import useModal from '@/composables/useModal'
import { useI18n } from 'vue-i18n'

import useTranslations from '@/composables/useTranslations'
import ModalWrapper from '@/components/ModalWrapper.vue'
import TheHeading from '@/components/TheHeading.vue'
import TheButton from '@/components/TheButton.vue'
import ContentRenderer from '@/components/ContentRenderer'

const DIRECTION_RTL = ['ar', 'fa-AF', 'ku', 'ps']

export default defineComponent({
  name: 'ModalConcept',
  components: {
    TheHeading,
    TheButton,
    ModalWrapper,
    ContentRenderer,
  },

  setup() {
    const { getLocalizationById } = useLocalizations()
    const { fetchObject } = useContents()
    const { getStructuresChildrens, fetchStructuresChildren } = useStructures()
    const { tenant } = useTenant()
    const { isModalOpen, modalContent, closeModal } = useModal()
    const { gqlContentQuery, isFallbackLanguage } = useTranslations()
    const { locale } = useI18n()
    const conceptLocale = computed(
      () => modalContent.value.options?.translation,
    )
    const contentItems = computed(() =>
      getStructuresChildrens(
        modalContent.value.content.id,
        'OBJECT',
        locale.value,
      ),
    )
    const translatedItems = computed(() =>
      getStructuresChildrens(
        modalContent.value.content.id,
        'OBJECT',
        conceptLocale.value,
      ).filter((child) =>
        getLocalizationById(child.contentId, conceptLocale.value, 'content'),
      ),
    )
    const direction = computed(() => {
      if (DIRECTION_RTL.some((drtl) => drtl === conceptLocale.value)) {
        return 'rtl'
      }

      return 'ltr'
    })

    onMounted(async () => {
      await fetchStructuresChildren(
        modalContent.value.content.id,
        {
          limit: 100,
          filter: `{"type": "OBJECT"}`,
        },
        {
          namespace: tenant.value.concepts.namespace,
        },
      )
      contentItems.value.forEach((ci) => {
        if (ci.contentId) {
          if (!conceptLocale.value && isFallbackLanguage.value) {
            fetchObject(ci.contentId, {
              namespace: tenant.value.concepts.namespace,
            })
          } else {
            gqlContentQuery(ci.contentId, conceptLocale.value, {
              namespace: tenant.value.concepts.namespace,
            }).catch(() => {
              // silence
            })
          }
        }
      })
    })

    return {
      direction,
      contentItems,
      translatedItems,
      conceptLocale,
      isModalOpen,
      closeModal,
      modalContent,
    }
  },
})
</script>

<style lang="scss">
.c-modal-concept__wrapper {
  @include bp(medium) {
    padding-right: rem(36px);
  }
}

.c-modal-concept__top-heading {
  margin-top: 0;
}

.c-modal-concept__top-close {
  position: absolute;
  top: rem(33px);
  right: rem(33px);

  svg {
    margin-right: 0;
  }
}
</style>
