<template>
  <div :class="['c-modal-wrapper', classes]">
    <div class="c-modal-wrapper__content">
      <slot name="content" />
    </div>
    <div class="c-modal-wrapper__footer">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    title: String,
    classes: String,
  },
})
</script>

<style lang="scss">
.c-modal-wrapper {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: rem(16px);
  right: rem(16px);
  max-width: calc($app-max-width - rem(32px));
  margin: 0 auto;
  border-radius: rem(16px);
  background: $modal__bg;
  overflow: hidden auto;
  max-height: 95vh;

  .c-modaldashboardtheme &,
  .c-modaldashboardstudent & {
    top: 2.5vh;
    transform: translateY(0%);
    max-width: calc(1280px - 2rem);
  }
}

.c-modal-wrapper__header-title {
  font-size: rem(32px);
  margin: 0;
}

.c-modal-wrapper__content {
  line-height: rem(28px);
  padding: rem(30px);
}

.c-modal-wrapper__footer {
  display: flex;
  justify-content: center;
  align-items: center;

  .c-modalaction & {
    padding: 0 rem(48px) rem(36px) rem(48px);
  }
}
</style>
