import { utils } from '@/core'

const customDataByEnv = {
  namespace: {
    production: '8b932a4e-b93d-4b0c-aa46-9076be416828',
    development: 'e0aa077e-7721-49a3-b7b8-ef1299f32ac7',
  }
}

const config = {
  name: 'Økonomiportalen',
  slug: 'okonomiske-programfag',
  namespace: utils.getDataByEnv('namespace', customDataByEnv),
  analytics: {
    gtm: '',
  },
  assets: {},
  styles: [],
  langs: [
    {
      code: 'nb',
      label: 'Bokmål',
    },
    {
      code: 'nn',
      label: 'Nynorsk',
    },
  ],
  concepts: {
    namespace: utils.getDataByEnv('namespace', customDataByEnv),
    slug: 'concepts',
    langs: [],
  }
}

export default config
