<template>
  <Sidebar v-if="!isLogin" />
  <div class="app__main" :class="{ 'app__main--login': isLogin }">
    <Topbar v-if="!isLogin" />
    <router-view />
    <Footer v-if="isLogin" />
    <Modal />
  </div>
</template>

<script>
import { computed, onBeforeMount, watch } from 'vue'
import { useRoute } from 'vue-router'
import { initSync, getSetting, settings } from '@/services/userSettings'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useTenant } from '@/core'

import Topbar from '@/components/Topbar'
import Footer from '@/components/Footer'
import Sidebar from '@/components/Sidebar'
import Modal from '@/components/Modal.vue'

export default {
  name: 'App',

  components: {
    Topbar,
    Footer,
    Sidebar,
    Modal,
  },

  props: {
    tenantConfig: Object,
  },

  setup(props) {
    const router = useRoute()
    const isLogin = computed(() => router.name === 'Login')
    const { locale } = useI18n()
    const store = useStore()
    const userId = computed(() => store.getters['user/userId'])
    const { initTenant } = useTenant()

    watch(userId, async (curr) => {
      if (curr) {
        await initSync()
      }
      const savedLang = (await getSetting(settings.lang))?.value
      if (savedLang) {
        locale.value = savedLang
      }
    })

    onBeforeMount(async () => {
      initTenant(props.tenantConfig)
    })
    return {
      router,
      isLogin,
    }
  },
}
</script>

<style lang="scss">
@import './assets/scss/main.scss';

#app {
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $color-black;
}

.app__main {
  background: $color-grey-light;
  min-height: calc(100vh - 2.5rem);
  padding: 1.25rem;
  margin-left: 0;

  @include bp-down(large) {
    .show-menu & {
      margin-left: 17rem;
    }
  }

  @include bp(large) {
    min-height: calc(100vh - 7.5rem);
    margin-left: 17rem;
    padding: 3.75rem;
  }

  &--login {
    background: $color-white;
    margin-left: 0;
    padding: 0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
}

a,
button,
input {
  @include a11yOutline();
}
</style>
