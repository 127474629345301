import { sortItemsByOrder } from '@/utils'
import i18n from '@/i18n'
import useLocalizations from '@/core/useLocalizations'
import useContents from '@/core/useContents'

const { getLocalizationById } = useLocalizations()
const { getObjectById } = useContents()

const getStructures = (state, getters) => (parentIds = [], withChildrens = false, type = 'DIRECTORY') => {
  let result = state.structures.filter(s => parentIds.some(parentId => parentId === s.id))

  result.map(s => {
    s.childrens = withChildrens ? getters.getStructuresChildrens(s.id, type) : []

    return s
  })

  return result
}

const getStructuresBySlugPath = (state, getters) => (slugPath, withChildrens = false, type = 'DIRECTORY') => {
  const locale = i18n.global.locale
  const result = state.structures.filter(s => slugPath === s.slugPath)

  result.map(s => {
    s.childrens = withChildrens ? getters.getStructuresChildrens(s.id, type) : []

    return s
  })

  if (result[0]?.name) {
    result[0].name = getLocalizationById(result[0]?.id, locale, 'structure')?.content.name || result[0].name
  }

  if (result[0]?.description) {
    result[0].description = getLocalizationById(result[0]?.id, locale, 'structure')?.content.description || result[0].description
  }

  return result[0] || {}
}

const getStructuresChildrens = (state) => (parentId, type) => {
  const locale = i18n.global.locale
  const items = JSON.parse(JSON.stringify(sortItemsByOrder(state.structures.filter(s => {
    if (type) {
      return s.parent === parentId && s.type === type
    }

    return s.parent === parentId
  }))))

  items.map(item => {
    item.name = getLocalizationById(item.id, locale, 'structure')?.content.name || item.name
    item.description = getLocalizationById(item.id, locale, 'structure')?.content.description || item.description
    if (item.type === 'OBJECT' && item.contentId) {
      item.contentData = getObjectById(item.contentId, locale)
    }

    return item
  })

  return items
}

const getStructuresNodeOrder = state => (node, type = 'DIRECTORY') => {
  if (!node) return ''

  return sortItemsByOrder(state.structures.filter(s => s.parent === node.parent && s.type === type)).findIndex(ch => ch.id === node.id) + 1
}

const getObject = state => contentId => {
  return state.objects.filter(obj => obj.id === contentId)[0]
}

export default {
  getObject,
  getStructures,
  getStructuresBySlugPath,
  getStructuresChildrens,
  getStructuresNodeOrder
}
