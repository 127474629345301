import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  user: null,
  role: null,
  hasTeacherAccess: false,
  accessToken: null,
}

export default {
  state,
  actions,
  getters,
  mutations,
  namespaced: true,
}
