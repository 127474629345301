import { createApp } from 'vue'
import { createHead } from '@vueuse/head'

import 'whatwg-fetch'

import App from './App.vue'
import router from './router'
import store from './store'
import i18n from '@/i18n'
import { createGtm } from '@gtm-support/vue-gtm'
import { mdHtml } from '@/directives'
import tenantConfig from '@/config/tenants/economy-portal'

import 'katex/dist/katex.css'
import 'normalize.css'

const head = createHead()

createApp(App, {
  tenantConfig
})
  .use(head)
  .use(store)
  .use(router)
  .use(i18n)
  .use(createGtm({
    id: 'GTM-MCQ3SJG'
  }))
  .directive('md-html', mdHtml)
  .mount('#app')
