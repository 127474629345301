<template>
  <component
    :is="currentComponent"
    class="c-button"
    :class="[
      { 'c-button--icon': icon },
      `c-button--${currentComponent}`,
      { 'c-button--pink': pink },
      { 'c-button--blue': blue },
    ]"
    v-bind="attributes"
  >
    <Icon v-if="icon" :icon="icon" />
    <slot> </slot>
  </component>
</template>

<script>
import { computed } from 'vue'

import Icon from '@/components/Icon'

export default {
  name: 'TheButton',

  components: {
    Icon,
  },

  props: {
    to: String,
    icon: String,
    pink: Boolean,
    blue: Boolean,
  },

  setup(props) {
    const currentComponent = computed(() => {
      if (props.to) {
        return 'router-link'
      } else {
        return 'button'
      }
    })
    const attributes = computed(() => {
      if (currentComponent.value === 'router-link') {
        return {
          to: props.to,
        }
      } else {
        return {}
      }
    })

    return {
      attributes,
      currentComponent,
    }
  },
}
</script>

<style lang="scss">
.c-button {
  display: inline-block;
  text-decoration: none;
  color: $color-blue-dark;
  font-size: 1rem;

  &--icon {
    font-weight: 400;
    display: inline-flex;
    align-items: center;

    svg {
      margin-right: 0.9375rem;
      min-width: 19px;
    }

    &:hover,
    &.router-link-active {
      font-weight: 600;
    }
  }

  &--button {
    @include btnReset();
  }

  &--pink {
    font-size: 1rem;
    font-weight: 500;
    color: $color-button2-text;
    background: $color-button2;
    border-radius: 0.625rem;
    padding: 1rem 3.875rem;
  }

  &--blue {
    font-size: 1rem;
    font-weight: 500;
    color: $color-button-text;
    background: $color-button;
    border-radius: 0.625rem;
    padding: 1rem 3.875rem;
  }
}
</style>
