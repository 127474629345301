<template>
  <div class="c-footer">
    <FHFooter class="c-footer__fh" hide-social />
  </div>
</template>

<script>
import FHFooter from '@forlagshuset/v-small-footer'

export default {
  components: {
    FHFooter
  }
}
</script>

<style lang="scss">
.c-footer {
  margin-top: auto;
}

.c-footer__fh {
  background: $footer-bg;
  
  .c-contact-link__text {
    color: $color-black;
  }

  svg .st0 {
    fill: $color-black;
  }
}
</style>
