<template>
  <nav class="c-sidebar-menu">
    <ul class="c-sidebar-menu__wrapper">
      <li
        class="c-sidebar-menu__item"
        :class="[item.class]"
        v-for="item in menu"
        :key="item.title"
      >
        <TheButton
          :to="item.to"
          :icon="item.icon"
          :class="{ 'router-link-active': route.path.indexOf(item.to) !== -1 }"
          @click.prevent="closeMenu(item.to)"
        >
          {{ item.title }} 
        </TheButton>
      </li>
    </ul>
  </nav>
</template>

<script>
import { computed } from 'vue'
import { useRoute } from 'vue-router'

import i18n from '@/i18n'

import TheButton from '@/components/TheButton'

export default {
  name: 'SidebarMenu',

  components: {
    TheButton,
  },

  emits: ['close-menu'],

  setup(props, { emit }) {
    const route = useRoute()
    const menu = computed(() => [
      {
        title: i18n.global.t('SIDEBAR_FAG'),
        to: '/fag',
        icon: 'IconDashboard',
        class: 'link-fag',
      },
      {
        title: i18n.global.t('SIDEBAR_TOOLS'),
        to: '/verktoy',
        icon: 'IconClip',
        class: 'link-tools',
      },
      {
        title: i18n.global.t('SIDEBAR_CONCEPTS'),
        to: '/fagbegreper',
        icon: 'IconFagbegreper',
        class: 'link-fagbegreper',
      },
      {
        title: `${i18n.global.t('SIDEBAR_THEME')} (${i18n.global.t('COMING').toLowerCase()})`,
        to: '/theme',
        icon: 'IconTopic',
        class: 'link-theme coming-soon',
      },
      {
        title: `${i18n.global.t('SIDEBAR_CASE')} (${i18n.global.t('COMING').toLowerCase()})`,
        to: '/case',
        icon: 'IconCase',
        class: 'link-case coming-soon',
      },
      {
        title: i18n.global.t('SIDEBAR_SETTINGS'),
        to: '/settings',
        icon: 'IconSettings',
        class: 'link-settings',
      },
    ])

    const closeMenu = to => {
      emit('close-menu', to)
    }

    return {
      menu,
      route,
      closeMenu,
    }
  },
}
</script>

<style lang="scss">
.c-sidebar-menu__wrapper {
  margin: 0;
  padding: 0;
}

.c-sidebar-menu__item {
  list-style-type: none;
  
  &.link-settings {
    display: none;
    @include bp(large) {
      display: none;
    }
  }

  &:not(:first-child) {
    margin-top: 1.25rem;
  }

  &.coming-soon {
    pointer-events: none;
    opacity: 0.5;
    padding-right: 1rem;
  }
}
</style>
