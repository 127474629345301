import { getObjectsAll, getObject } from '@/services/contents'
import { getStructuresAll, getStructuresChildren, getStructuresNode } from '@/services/structures'

const namespace = process.env.VUE_APP_COSS_NAMESPACE

const setStructuresParents = async ({ commit }) => {
  const structures = await getStructuresAll(namespace, {
    limit: 100,
    filter: `{"parent": null}`
  })

  commit('SET_STRUCTURES', structures)

  return structures
}

const setStructuresChildren = async ({ commit }, { slugPath, query }) => {
  const structures = await getStructuresChildren(namespace, encodeURIComponent(slugPath), query)
  structures.data.map(s => {
    s.slugPath = slugPath + `/${s.slug}`

    return s
  })
  commit('SET_STRUCTURES', structures)

  return structures
}

const setStructure = async ({ commit }, slugPath) => {
  const structure = await getStructuresNode(namespace, encodeURIComponent(slugPath))

  structure.slugPath = slugPath
  commit('SET_STRUCTURES', { data: [structure] })

  return structure
}

const setObjects = async ({ commit }, query) => {
  const objects = await getObjectsAll(namespace, query)

  commit('SET_OBJECTS', objects)

  return objects
}

const setObject = async ({ state, commit }, id) => {
  if (state.objects.some(obj => obj.id === id)) return

  const obj = await getObject(namespace, id)

  commit('SET_OBJECTS', { data: [obj] })

  return obj
}

export default {
  setStructuresParents,
  setStructuresChildren,
  setStructure,
  setObjects,
  setObject
}
