import { fetchSH } from '@/api'
import { buildQuery } from '@/utils'
import useTranslations from '@/composables/useTranslations'

const endpoint = 'structures'

/* Structures CRUD */
const getStructuresAll = (namespace, query) => fetchSH(`${endpoint}/${namespace}/nodes${buildQuery(query)}`)

const getStructuresChildren = async (namespace, idOrSlug) => {
  const { gqlStructureChildrenQuery } = useTranslations()

  return await gqlStructureChildrenQuery(idOrSlug, 100)
}

const getStructuresNode = async (namespace, idOrSlug) => {
  const { gqlSingleContent } = useTranslations()

  return await gqlSingleContent(idOrSlug)
}

export {
  getStructuresAll,
  getStructuresChildren,
  getStructuresNode,
}
