export const langs = [
  {
    value: 'nb',
    label: 'Bokmål',
  },
  {
    value: 'nn',
    label: 'Nynorsk',
  },
]
