<template>
  <div
    :class="[
      'v-content-renderer',
      { 'v-content-renderer--borderless': borderless },
    ]"
  >
    <TheHeading class="v-content-renderer__title">{{ title }}</TheHeading>
    <ContentToC parent-class="v-content-renderer" />
    <div v-if="contentItems.length" class="v-content-renderer__wrapper">
      <div
        class="v-content-renderer__element"
        :class="[`v-content-renderer__element--${item.contentType}`]"
        v-for="item in contentItems"
        :key="item.id"
      >
        <component
          v-if="item.contentData"
          :is="loadComponent(item.contentType)"
          :key="item.id"
          :data="item.contentData"
          :item="item.contentId"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

import TheHeading from '@/components/TheHeading'
import ContentToC from '@/components/ContentToC'

export default {
  name: 'ContentRenderer',

  props: {
    title: String,
    contentItems: Array,
    borderless: Boolean,
  },

  components: {
    ContentToC,
    TheHeading,
  },

  setup() {
    const loadComponent = (component) =>
      defineAsyncComponent(() => import(`@/components/Elements/${component}`))

    return {
      loadComponent,
    }
  },
}
</script>

<style lang="scss">
.v-content-renderer {
  margin-top: 2.8125rem;
  max-width: 1021px;

  &--borderless {
    margin-top: 0;
  }

  .box {
    margin-top: 4rem;
    background: $oppstart-primary-light;
    padding: 1rem;
    border-radius: 10px;

    @include bp(medium) {
      padding: 2.25rem 3.375rem 4.5rem;
    }

    h2 {
      margin-top: 0;
    }

    ul {
      margin-top: 2rem;
      max-width: 515px;

      li {
        margin-top: 1rem;

        a {
          display: block;

          @include bp(medium) {
            display: inline-block;
            float: right;
          }

          &::after {
            display: inline-block;
            content: '→';
            margin-left: 0.5rem;
            font-size: 1.2rem;
          }
        }
      }
    }
  }
}

.v-content-renderer__title {
  font-size: 1.875rem;
  font-weight: 500;
}

.v-content-renderer__element {
  overflow: auto;
}

.v-content-renderer__wrapper {
  margin-top: 1.5rem;

  @include bp(medium) {
    background: $color-white;
    padding: 0 4.0625rem;
  }

  .v-content-renderer--borderless & {
    @include bp(medium) {
      padding: 0;
    }
  }
}
</style>
