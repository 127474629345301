import { computed } from 'vue'
import store from '@/store/index'
import DB from '@/core/services/db'

const userId = computed(() => store.getters['user/userId'])
const db = new DB()
const prefix = computed(
  () => `${process.env.VUE_APP_COSS_NAMESPACE}-${userId.value}`,
)

const settings = {
  lang: 'lang',
}

const createOrUpdateSetting = async (settingId, value) => {
  try {
    const item = await db.createOrUpdate({
      _id: `${prefix.value}-settings-${settingId}`,
      value,
    })
    await db.syncData()
    return item
  } catch (err) {
    console.error(err)
  }
}

const getSetting = async (settingId) => {
  try {
    const value = await db.getData(`${prefix.value}-settings-${settingId}`)

    return value
  } catch (err) {
    return null
  }
}

const initSync = async () => {
  await db.initSync()
}

export { settings, getSetting, createOrUpdateSetting, initSync }
