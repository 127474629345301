import auth from '@/auth'
import jwt_decode from 'jwt-decode'

const teacherRoles = [
  'teacher',
  'institution',
  'institution-owner',
  'organization',
  'admin',
  'internal',
  'institution-manager',
]

const setUser = ({ commit }, user) => {
  commit('SET_USER', user)
}

const setToken = ({ commit, dispatch }) => {
  const decodedToken = jwt_decode(auth.token)

  if (teacherRoles.includes(decodedToken.primary_role)) {
    dispatch('setUserRole', 'teacher')
  } else {
    dispatch('setUserRole', 'student')
  }

  commit('SET_ACCESS_TOKEN')
}

const setUserRole = ({ commit }, payload) => {
  commit('SET_USER_ROLE', payload)
}

const setTeacherAccess = ({ commit }, payload) => {
  commit('SET_TEACHER_ACCESS', payload)
}

const logout = ({ commit }) => {
  const redirectUrl = `${auth.logoutUrl}&token=${encodeURIComponent(
    auth.token,
  )}`

  commit('LOGOUT_USER')
  auth.logout(redirectUrl)
}

export default {
  setUser,
  setUserRole,
  setToken,
  setTeacherAccess,
  logout,
}
