import { computed } from 'vue'
import i18n from '@/i18n'
import {
  queryContentWithLocale,
  queryStructureWithLocale,
  queryStructureChildrenWithLocale,
  querySingleContentWithLocale,
} from '@/graphql'
import { postGql } from '@/core/services/graphql'
import { useLocalizations, useContents, useStructures, useTenant } from '@/core'

const { tenant } = useTenant()
const { setObject } = useContents()
const { setStructure } = useStructures()
const { setLocalization, getLocalizationById } = useLocalizations()
const isFallbackLanguage = computed(
  () => tenant.value.langs[0].code === i18n.global.locale,
)

const gqlContentQuery = async (contentId, locale = '') => {
  const lang = locale || i18n.global.locale
  let gqlRes

  try {
    gqlRes = await postGql(process.env.VUE_APP_COSS_NAMESPACE, {
      query: queryContentWithLocale,
      variables: {
        objectId: contentId,
        locale: lang,
      },
    })
    setObject([gqlRes.data.content.data])

    if (gqlRes.data.content.data.localization) {
      gqlRes.data.content.data.localization.content = {
        ...gqlRes.data.content.data.localization,
      }

      gqlRes.data.content.data.localization.objectId = contentId
      gqlRes.data.content.data.localization.service = 'edtech.content'
      gqlRes.data.content.data.localization.locale = lang
      setLocalization(gqlRes.data.content.data.localization)
    }
  } catch {
    //
  }

  return gqlRes
}

const gqlStructureQuery = async (id) => {
  const locale = i18n.global.locale
  let gqlRes

  try {
    gqlRes = await postGql(process.env.VUE_APP_COSS_NAMESPACE, {
      query: queryStructureWithLocale,
      variables: {
        id,
        locale,
      },
    })

    gqlRes.data.structure.data.slugPath = id
    setStructure([gqlRes.data.structure.data])
    if (gqlRes.data.structure.data.localization.data) {
      gqlRes.data.structure.data.localization.data.objectId =
        gqlRes.data.structure.data.id
      setLocalization(gqlRes.data.structure.data.localization.data)
      gqlRes.data.structure.data.name =
        getLocalizationById(gqlRes.data.structure.data.id, locale, 'structure')
          ?.content.name || gqlRes.data.structure.data.name
      gqlRes.data.structure.data.description =
        getLocalizationById(gqlRes.data.structure.data.id, locale, 'structure')
          ?.content.description || gqlRes.data.structure.data.description
    }
  } catch {
    //
  }

  return gqlRes
}

const gqlStructureChildrenQuery = async (id, limit = 10, page = 0) => {
  const locale = i18n.global.locale
  let gqlRes

  try {
    gqlRes = await postGql(process.env.VUE_APP_COSS_NAMESPACE, {
      query: queryStructureChildrenWithLocale,
      variables: {
        id,
        locale,
        limit,
        page,
      },
    })

    gqlRes.data.structure.data.children.data.forEach((child) => {
      const childLocalization = child.localization.data
      delete child.localization
      setStructure([child])
      if (childLocalization) {
        childLocalization.objectId = child.id
        setLocalization(childLocalization)
      }
    })
  } catch {
    //
  }

  return gqlRes.data.structure.data.children
}

const gqlSingleContent = async (id) => {
  const locale = i18n.global.locale
  let gqlRes

  try {
    gqlRes = await postGql(process.env.VUE_APP_COSS_NAMESPACE, {
      query: querySingleContentWithLocale,
      variables: {
        id,
        locale,
      },
    })

    setStructure([gqlRes.data.structure.data])
    if (gqlRes.data.structure.data.localization.data) {
      gqlRes.data.structure.data.localization.data.objectId =
        gqlRes.data.structure.data.id
      setLocalization(gqlRes.data.structure.data.localization.data)
      gqlRes.data.structure.data.name =
        getLocalizationById(gqlRes.data.structure.data.id, locale, 'structure')
          ?.content.name || gqlRes.data.structure.data.name
      gqlRes.data.structure.data.description =
        getLocalizationById(gqlRes.data.structure.data.id, locale, 'structure')
          ?.content.description || gqlRes.data.structure.data.description
    }
  } catch {
    //
  }

  return gqlRes.data.structure.data
}

export default () => ({
  isFallbackLanguage,
  gqlContentQuery,
  gqlStructureQuery,
  gqlStructureChildrenQuery,
  gqlSingleContent,
})
