<template>
  <div v-if="toc.length" class="c-content-toc">
    <div class="c-content-toc__wrapper">
      <transition>
        <div
          class="c-content-toc__wrapper--gradient-right"
          v-if="showGradientRight"
        ></div>
      </transition>
      <transition>
        <div
          class="c-content-toc__wrapper--gradient-left"
          v-if="showGradientLeft"
        ></div>
      </transition>
      <nav class="c-content-toc__nav">
        <ul
          @scroll="handleGradient()"
          ref="navWrapper"
          class="c-content-toc__nav-wrapper"
        >
          <li
            :class="[
              'c-content-toc__nav-item',
              { 'c-content-toc__nav-item--current': item.id === currentId },
            ]"
            v-for="item in toc"
            :key="item.id"
          >
            <a
              :href="`#${item.id}`"
              class="c-content-toc__nav-link"
              @click.prevent="onAnchorScroll(item.id), $event.target.blur()"
              >{{ item.title }}</a
            >
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue'

export default {
  name: 'ContentToC',

  props: {
    parentClass: String,
  },

  setup(props) {
    const scrollOffset = 150
    let scrollTreshold = 0.4
    const toc = ref([])
    const renderInterval = ref(null)
    const currentId = ref(null)
    const navWrapper = ref(null)
    const clickScroll = ref(false)
    let scrollDebounce
    const tocBuilder = () => {
      const headings = Array.from(
        document.querySelectorAll(`.${props.parentClass}__wrapper h2`),
      )
      const items = []

      headings.forEach((h) => {
        items.push({
          title: h.innerText,
          id: h.id,
        })
      })

      toc.value = items
    }

    const onAnchorScroll = (element) => {
      const el = document.querySelector(`h2#${element}`)
      currentId.value = element
      clickScroll.value = true
      window.scrollTo({
        top: el.offsetTop - scrollOffset,
        behavior: 'smooth',
      })
    }

    const handleScroll = () => {
      window.clearTimeout(scrollDebounce)
      scrollDebounce = setTimeout(function () {
        clickScroll.value = false
      }, 50)
      if (!clickScroll.value) {
        toc.value.forEach((el) => {
          const element = document.querySelector(`h2#${el.id}`)
          const position = element.getBoundingClientRect()

          if (
            position.top <
            (window.innerHeight * scrollTreshold ||
              document.documentElement.clientHeight * scrollTreshold)
          ) {
            currentId.value = el.id
          }
        })
      }

      const activeEl = document.querySelector(
        '.c-content-toc__nav-item--current',
      )
      if (activeEl) {
        navWrapper.value.scrollTo({
          left: activeEl.offsetLeft,
          behavior: 'smooth',
        })
      }
    }

    const showGradientRight = ref(true)
    const showGradientLeft = ref(false)

    const handleGradient = () => {
      if (navWrapper.value) {
        const hasHorizontalScrollbar =
          navWrapper.value.scrollWidth > navWrapper.value.clientWidth
        const maxScrollLeft =
          navWrapper.value.scrollWidth - navWrapper.value.clientWidth

        if (hasHorizontalScrollbar) {
          if (navWrapper.value.scrollLeft >= maxScrollLeft) {
            showGradientRight.value = false
            showGradientLeft.value = true
          } else {
            showGradientRight.value = true
            showGradientLeft.value = false
          }
        } else {
          showGradientRight.value = false
          showGradientLeft.value = false
        }
      }
    }

    onMounted(() => {
      renderInterval.value = setInterval(() => {
        tocBuilder()
        handleGradient()
      }, 250)
      window.addEventListener('scroll', handleScroll)
      window.addEventListener('resize', handleGradient)
    })

    onUnmounted(() => {
      renderInterval.value = null
      window.removeEventListener('scroll', handleScroll)
      window.removeEventListener('resize', handleGradient)
    })

    return {
      toc,
      currentId,
      navWrapper,
      onAnchorScroll,
      handleGradient,
      showGradientRight,
      showGradientLeft,
    }
  },
}
</script>

<style lang="scss">
.c-content-toc {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  font-size: 1rem;
  background-color: $nav-bg-small;
  padding-top: 1rem;
  margin-bottom: 3rem;

  @include bp($medium) {
    background-color: $nav-bg-medium;
  }

  + div {
    border-top: 0 !important;
  }
  &__title {
    font-weight: 700;
  }
  &__wrapper {
    position: relative;
    border-bottom: 2px $chips-wrapper-border solid;

    @include bp(medium) {
      padding: 0 4.0625rem;
    }

    &--gradient-right,
    &--gradient-left {
      position: absolute;
      top: -0.25rem;
      height: 3.2rem;
      width: 3rem;
      pointer-events: none;
      transition: opacity 0.3s ease;
    }
    &--gradient-right {
      right: 0;
      @include bp(medium) {
        right: 4.0625rem;
      }
      background: linear-gradient(
        270deg,
        rgb(255, 255, 255) 0%,
        rgba(255, 255, 255, 0%) 100%
      );
    }
    &--gradient-left {
      left: 0;
      @include bp(medium) {
        left: 4.0625rem;
      }
      background: linear-gradient(
        90deg,
        rgb(255, 255, 255) 0%,
        rgba(255, 255, 255, 0%) 100%
      );
    }
    ::-webkit-scrollbar {
      height: 7px;
      cursor: pointer;
    }
    ::-webkit-scrollbar-thumb {
      background: $scrollbar-bg;
      border-radius: 62.438rem;
    }
  }
  &__nav {
    scroll-behavior: smooth;
    overflow-y: hidden;
    &-wrapper {
      padding: 0;
      display: flex;
      flex-wrap: nowrap;
      padding-left: 0;
      padding-bottom: 1.2rem;
      margin-block: 0;
      overflow-x: auto;
      text-align: center;
      white-space: nowrap;
    }
    &-item {
      list-style-type: none;
      &--current {
        .c-content-toc__nav-link {
          position: relative;
          padding-left: 2rem;
          background-color: $chips-bg;
          border: 0.125rem solid $chips-active-border;
          &::before {
            position: absolute;
            left: 0.5rem;
            top: 50%;
            transform: translateY(-50%);
            content: url("data:image/svg+xml, %3Csvg width='21' height='20' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M9.75 16.17 6.28 12.7a.996.996 0 1 0-1.41 1.41l4.18 4.18c.39.39 1.02.39 1.41 0L21.04 7.71a.996.996 0 1 0-1.41-1.41l-9.88 9.87z' fill='%23474747' /%3E%3C/svg%3E");
          }
        }
      }
    }
    &-link {
      display: block;
      border: 0.125rem solid $chips-bg;
      border-radius: 62.438rem;
      margin-right: 1rem;
      margin-top: 0.125rem;
      padding: 0.7rem 0.8rem;
      text-decoration: none;
      color: $chips-focus;
      &:focus {
        outline: 0.125rem dashed $chips-focus !important;
        border-radius: 62.438rem;
      }
      &:first-of-type {
        margin-left: 0.063rem;
      }
    }
  }
}
</style>
